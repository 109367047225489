import axiosApi from "../axios";
export default {
  async applyCoupun(Coupun) {
    return await axiosApi
      .put(`/cart/applyCoupon`, Coupun)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(JSON.stringify(e) + "this is an error ");
        return e.response?.data?.errors;
      });
  },
};
