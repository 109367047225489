import axiosApi from "../axios";
export default {
  async getusers(params) {
    return await axiosApi
      .get(`/users`, { params: params })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async updateuser(user) {
    return await axiosApi
      .put(`/users/${user._id}`, user)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createuser(user) {
    return await axiosApi
      .post(`/users`, user)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createmessage(message) {
    return await axiosApi
      .post(`/users/sendemail`, message)
      .then((res) => {
        console.log(res + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async getMe() {
    return await axiosApi
      .get(`/users/getMe`)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async getMyCourses() {
    return await axiosApi
      .get(`/users/getMyCourses`)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async updateMe(user) {
    return await axiosApi
      .put(`/users/updateMe`, user)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async updateMyCourse(course) {
    return await axiosApi
      .put(`/users/editMyCourse`, course)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async updateMyQuiz(quiz) {
    return await axiosApi
      .put(`/users/updateMyQuiz`, quiz)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },

  async changePassword(user) {
    return await axiosApi
      .put(`/users/changePassword/${user._id}`, user)
      .then((res) => {
        console.log(res + "");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },

  async deleteuser(user) {
    return await axiosApi
      .delete(`/users/${user.id}`)
      .then((res) => {
        return res.status;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response;
      });
  },
};
