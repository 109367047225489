<template>
  <div class="list-view product-checkout mt-0 row m-0">
    <b-col md="9">
      <div class="checkout-items">
        <b-card
          v-for="item in items"
          :key="item.id"
          class="ecommerce-card w-100"
          no-body
        >
          <!-- item Image -->
          <b-row class="m-0">
            <div class="col-md-3">
              <b-img
                :src="item.imageCover"
                class="m-50 w-100"
                style="height: 200px"
                :alt="`${item.name}-${item.id}`"
              />
            </div>

            <div class="col-md-6 mt-2">
              <div class="item-name">
                <h6 class="mb-0">
                  {{ item.title }}
                </h6>
              </div>
            </div>

            <!-- item Options/Actions -->
            <div class="col-md-3 mt-2">
              <b-row class="m-0" align-h="between">
                <h5 class="text-capitalize">Price</h5>
                <b-card-text
                  v-if="item.priceAfterDiscount"
                  class="text-success"
                >
                  <b-badge variant="success" class="m-25">
                    ${{ item.priceAfterDiscount }}
                  </b-badge>
                  <span
                    style="
                      text-decoration: line-through red;
                      text-decoration-thickness: 1.5px;
                    "
                    class="text-secondary text-decoration-line-through"
                  >
                    {{ item.price }} $</span
                  >
                </b-card-text>
                <b-card-text v-else class="text-success">
                  {{ item.price }} $
                </b-card-text>
              </b-row>
              <b-row class="m-0" align-h="between">
                <h5 class="text-capitalize">Created At</h5>
                <b-card-text>
                  {{ new Date(item.createdAt).toISOString().slice(0, 10) }}
                  <feather-icon icon="ClockIcon" class="mr-50 text-primary" />
                </b-card-text>
              </b-row>
              <b-row class="m-0 mt-25" align-h="between">
                <h5 class="text-capitalize">Sections</h5>
                <b-card-text>
                  {{ item.sections.length }}
                  <feather-icon
                    icon="AlignJustifyIcon"
                    class="mr-50 text-primary"
                  />
                </b-card-text>
              </b-row>
              <b-row class="m-0 mt-25" align-h="between">
                <h5 class="text-capitalize">Duration</h5>
                <b-card-text>
                  {{ item.duration }} m
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-50 text-primary"
                  />
                </b-card-text>
              </b-row>
              <b-row class="m-0 mt-25" align-h="center">
                <b-button
                  variant="light"
                  class="mt-1 remove-wishlist mb-25"
                  @click="removeItemFromCart(item)"
                >
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span>Remove</span>
                </b-button>
              </b-row>
            </div>
          </b-row>

          <!-- item Details: Card Body -->
        </b-card>
      </div>
    </b-col>
    <b-col md="3">
      <div class="checkout-options">
        <b-card>
          <label class="section-label mb-1">Options</label>
          <b-input-group class="input-group-merge coupons">
            <b-form-input v-model="coupon" placeholder="Coupons" />

            <b-input-group-append>
              <b-button @click="applyCoupun" variant="outline-primary">
                Apply
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <hr />
          <div class="price-details">
            <h6 class="price-title">Price Details</h6>
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title">Total</div>
                <div class="detail-amt">${{ totalAmount }}</div>
              </li>
            </ul>
            <hr />
            <ul class="list-unstyled" v-if="totalAfterCoupon != null">
              <li class="price-detail">
                <div class="detail-title detail-total">Total After Coupun</div>
                <div class="detail-amt font-weight-bolder text-success">
                  ${{ totalAfterCoupon }}
                </div>
              </li>
            </ul>
            <!-- @click="$emit('next-step')" -->
            <b-button variant="primary" block @click="makeOrder">
              Place Order
            </b-button>
          </div>
        </b-card>
      </div>
    </b-col>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
} from "bootstrap-vue";
import store from "@/store";
import { ref } from "@vue/composition-api";
import { formatDate } from "@core/utils/filter";
// import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import cartApi from "@/apiServices/cartApi";
import couponApi from "@/apiServices/couponApi";
import orderApi from "@/apiServices/ordersApi";
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import usersApi from "@/apiServices/userApi";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    ToastificationContent,
    BBadge,
    BFormSpinbutton,
  },
  data() {
    return {
      cartId: null,
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      totalAfterCoupon: null,
      coupon: "",
    };
  },
  created() {
    this.getMyCart();
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.items.forEach((i) => {
        total += i.priceAfterDiscount || i.price;
      });
      return total;
    },
  },
  methods: {
    ...mapActions("cart", ["removeitemFromCart", "additemInCart"]),

    getMyCart() {
      cartApi.getUsersCart().then((response) => {
        if (response.data) {
          console.log(response.data);
          this.items = response.data?.cartItems;
          this.cartId = response.data?._id;

          this.items.forEach((element) => {
            this.additemInCart(element);
          });
        } else {
        }
      });
    },

    async applyCoupun() {
      await couponApi.applyCoupun({ coupon: this.coupon }).then((response) => {
        console.log(response);

        if (response && response.data) {
          let couponData = response.data.data;
          this.totalAfterCoupon = couponData.totalPriceAfterDiscount;
          console.log(couponData.totalPriceAfterDiscount);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Coupon is invalid or expired",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    async makeOrder() {
      await orderApi.createOrder(this.cartId).then((response) => {
        console.log(response);
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Your order Have Been Received",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "account-setting" });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    async removeItemFromCart(item) {
      await cartApi.removeCartItem(item._id).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.removeitemFromCart(item);
          this.items = response.data?.cartItems;
        } else {
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>
<style></style>
